









































































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { mapActions } from "vuex";
import { reserveerApi } from "@/lib/backend";
import type {
	IomodelsCoachMemberExtern,
	IomodelsCoachappIntake,
	IomodelsCoachappIntakePersonalInfo,
} from "@/lib/backend/club.api";
import {
	ContentType,
	type IomodelsProefsporterLes,
	type IomodelsWelkomstFormulierExtern,
} from "@/lib/backend/reserveer.api";
import { DEFAULT_LANGUAGE, DEFAULT_LOCATION } from "@/lib/constants/misc";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queries } from "@/lib/query/queries";
import { logger } from "@/logger";
import { usePopupStore } from "@/pinia/popup";
import { getDateFromDatum, getDateFromDatumAndTijd } from "@/utils/date";

const FORMS = [
	"PfgFormWelkomstformulier",
	"PfgFormIntake",
	"PfgFormProefsportenGroepsles",
	"PfgFormProefsporterFitness",
] as const;
type Form = (typeof FORMS)[number];

export default defineComponent({
	setup() {
		const popupStore = usePopupStore();
		const { slug: currentGymSlug, gym: currentGym } = useCurrentGym();

		const activeForm = ref<Form>("PfgFormWelkomstformulier");

		const formData = ref<
			{
				telefoonNummer: string;
				doel: string;
				bereiken: string;
				frequentie: number;
				groepsles: number;
				interests: Array<{
					slug: string;
					label: string;
					key: string;
					value: boolean;
				}>;
				locatie?: IomodelsProefsporterLes["locatie"];
			} & Omit<IomodelsCoachappIntake, "personalInfo"> &
				IomodelsCoachappIntakePersonalInfo &
				Pick<IomodelsCoachMemberExtern, "coachLid" | "coachingsGroep"> &
				Pick<IomodelsProefsporterLes, "verwijzing">
		>({
			geslacht: "",
			geboortedatum: undefined as unknown as string,
			voornaam: undefined as unknown as string,
			achternaam: undefined as unknown as string,
			email: undefined as unknown as string,
			telefoonNummer: undefined as unknown as string,
			telefoonMobiel: undefined as unknown as string,
			doel: undefined as unknown as string,
			bereiken: undefined as unknown as string,
			frequentie: undefined as unknown as number,
			verwijzing: undefined,
			groepsles: undefined as unknown as number,
			interests: [
				{
					slug: "groepslessen",
					label: "Groepslessen",
					key: "groepslessen",
					value: false,
				},
				{
					slug: "functioneel-trainen",
					label: "Functioneel trainen",
					key: "functioneelTrainen",
					value: false,
				},
				{
					slug: "buiten-sporten",
					label: "Buiten sporten",
					key: "buitenSporten",
					value: false,
				},
				{
					slug: "profit-gym-gym-app",
					label: "ProFit Gym app",
					key: "profitGymApp",
					value: false,
				},
				{
					slug: "zonnebank",
					label: "Zonnebank",
					key: "zonnebank",
					value: false,
				},
				{
					slug: "spierballen-in-8-weken",
					label: "Spierballen in 8 weken",
					key: "spierballenIn8Weken",
					value: false,
				},
				{
					slug: "afvallen-in-8-weken",
					label: "Afvallen in 8 weken",
					key: "afvallenIn8Weken",
					value: false,
				},
				{
					slug: "sportvasten-switch-me",
					label: "Sportvasten SwitchMe",
					key: "switchMe",
					value: false,
				},
				{
					slug: "fysiotherapie",
					label: "Fysiotherapie",
					key: "fysiotherapie",
					value: false,
				},
				{
					slug: "personal-training",
					label: "Personal Training",
					key: "personalTraining",
					value: false,
				},
			],
			locatie: currentGymSlug.value,
			language: DEFAULT_LANGUAGE,

			// Intake
			adres: undefined as unknown as string,
			postcode: undefined as unknown as string,
			plaats: undefined as unknown as string,
			coachingsGroep: undefined,
			coachLid: undefined as unknown as number,
			doelFrequentie: 1,
			medischCheck: false,
			hartklachten: false,
			kortademigheid: false,
			longklachten: null,
			spierBlessures: {
				nek: false,
				schouder: false,
				arm: false,
				rug: false,
				bovenbeen: false,
				onderbeen: false,
				description: "",
			},
			gewrichtsAandoening: {
				nek: false,
				schouder: false,
				pols: false,
				rug: false,
				heup: false,
				knie: false,
				enkel: false,
				voet: false,
				description: "",
			},
			overigeKlachten: null,
			uitgezonderdeOefeningen: null,
			consultBijFysiotherapeut: false,
			interesses: [],
			diensten: [],

			...(process.env.NODE_ENV === "development" && {
				geslacht: "m",
				geboortedatum: "10-9-1998",
				voornaam: "Max",
				achternaam: "Altena",
				email: "max+test@stijlbreuk.nl",
				telefoonNummer: "06-12345678",
				telefoonMobiel: "06-12345678",
				doel: "Ik wil leren spelen",
				bereiken: "Ik wil leren spelen",
				frequentie: 1,
				verwijzing: "vrienden",
				adres: "Veemarktstraat 34-36",
				postcode: "5038 CV",
				plaats: "Tilburg",
				coachingsGroep: "30%",
				coachLid: 3416,
			}),
		});

		watch(currentGymSlug, (newValue) => (formData.value.locatie = newValue));

		const { isLoading, data } = useQuery(queries.lesson.current());

		const overridenIsLoading = ref(false);
		const loading = computed(() => isLoading.value || overridenIsLoading.value);

		const lessons = computed(() => {
			if (!data.value) {
				return [];
			}

			const NOW = new Date();

			const lessons = data.value
				.filter(
					(les) =>
						les.vestiging_naam === currentGym.value?.naam || currentGym.value?.naam === "all",
				)
				.filter((les) => NOW < getDateFromDatumAndTijd(les.datum, les.tijd));

			return lessons;
		});

		return {
			FORMS,
			activeForm,
			popupStore,
			currentGymSlug,
			currentGym,
			formData,
			overridenIsLoading,
			loading,
			lessons,
		};
	},
	methods: {
		...mapActions("modal", { openModal: "openModal" }),
		...mapActions("trial", ["saveTrialSports", "saveTrialGrouplesson"]),
		getTitle(form: Form): string {
			switch (form) {
				default:
				case "PfgFormWelkomstformulier":
					return "Welkomstformulier";

				case "PfgFormProefsportenGroepsles":
					return "Proefsporter groepsles";

				case "PfgFormProefsporterFitness":
					return "Proefsporter fitness";

				case "PfgFormIntake":
					return "Intake";
			}
		},
		async reset() {
			this.formData = {
				geslacht: "",
				geboortedatum: undefined as unknown as string,
				voornaam: undefined as unknown as string,
				achternaam: undefined as unknown as string,
				email: undefined as unknown as string,
				telefoonNummer: undefined as unknown as string,
				telefoonMobiel: undefined as unknown as string,
				doel: undefined as unknown as string,
				bereiken: undefined as unknown as string,
				frequentie: undefined as unknown as number,
				verwijzing: undefined,
				groepsles: undefined as unknown as number,
				interests: [
					{
						slug: "groepslessen",
						label: "Groepslessen",
						key: "groepslessen",
						value: false,
					},
					{
						slug: "functioneel-trainen",
						label: "Functioneel trainen",
						key: "functioneelTrainen",
						value: false,
					},
					{
						slug: "buiten-sporten",
						label: "Buiten sporten",
						key: "buitenSporten",
						value: false,
					},
					{
						slug: "profit-gym-gym-app",
						label: "ProFit Gym app",
						key: "profitGymApp",
						value: false,
					},
					{
						slug: "zonnebank",
						label: "Zonnebank",
						key: "zonnebank",
						value: false,
					},
					{
						slug: "spierballen-in-8-weken",
						label: "Spierballen in 8 weken",
						key: "spierballenIn8Weken",
						value: false,
					},
					{
						slug: "afvallen-in-8-weken",
						label: "Afvallen in 8 weken",
						key: "afvallenIn8Weken",
						value: false,
					},
					{
						slug: "sportvasten-switch-me",
						label: "Sportvasten SwitchMe",
						key: "switchMe",
						value: false,
					},
					{
						slug: "fysiotherapie",
						label: "Fysiotherapie",
						key: "fysiotherapie",
						value: false,
					},
					{
						slug: "personal-training",
						label: "Coach moment",
						key: "personalTraining",
						value: false,
					},
				],
				locatie: this.currentGymSlug,

				// Intake
				adres: undefined as unknown as string,
				postcode: undefined as unknown as string,
				plaats: undefined as unknown as string,
				coachingsGroep: undefined,
				coachLid: undefined as unknown as number,
				doelFrequentie: 1,
				medischCheck: false,
				hartklachten: false,
				kortademigheid: false,
				longklachten: null,
				spierBlessures: {
					nek: false,
					schouder: false,
					arm: false,
					rug: false,
					bovenbeen: false,
					onderbeen: false,
					description: "",
				},
				gewrichtsAandoening: {
					nek: false,
					schouder: false,
					pols: false,
					rug: false,
					heup: false,
					knie: false,
					enkel: false,
					voet: false,
					description: "",
				},
				overigeKlachten: null,
				uitgezonderdeOefeningen: null,
				consultBijFysiotherapeut: false,
				interesses: [],
				diensten: [],
				language: "nld",
			};

			await this.$nextTick();
			(this.$refs.form as { errors: Map<string, string>; $validator: unknown }).errors.clear();
		},
		async save() {
			// @ts-expect-error typed?
			const isValid = await (
				this.$refs.form as { errors: Map<string, string>; $validator: unknown }
			).$validator.validateAll();

			if (!isValid) return;

			try {
				this.overridenIsLoading = true;

				switch (this.activeForm) {
					case "PfgFormWelkomstformulier": {
						await reserveerApi.welkomstformulier.postWelkomstFormulier(this.getMappedFormData(), {
							type: ContentType.Json,
						});

						this.popupStore.open({
							title: "Gelukt!",
							body: "Het welkomstformulier is succesvol opgeslagen",
							buttons: {
								confirm: "Sluiten",
							},
							callback: () => this.reset(),
						});
						return;
					}
					case "PfgFormProefsportenGroepsles": {
						const response = await reserveerApi.proeflesDeelname.postProeflesDeelname({
							...this.getMappedFormData(),
							lesId: Number(this.formData.groepsles),
						});

						this.popupStore.open({
							title: "Gelukt!",
							body: "Het proefsporter groepsles-formulier is succesvol opgeslagen",
							buttons: {
								confirm: "Sluiten",
							},
							callback: () => {
								this.$router.push({
									name: "Gebruiker",
									params: {
										id: String(response.data.tijdelijkId),
									},
								});
								this.reset();
							},
						});
						return;
					}
					case "PfgFormProefsporterFitness": {
						await reserveerApi.proefFitness.postProefFitness(this.getMappedFormData(), {
							type: ContentType.Json,
						});

						this.popupStore.open({
							title: "Gelukt!",
							body: "Het proefsporter fitness-formulier is succesvol opgeslagen",
							buttons: {
								confirm: "Sluiten",
							},
							callback: () => this.reset(),
						});
						return;
					}
					default:
						return;
				}
			} catch (error) {
				logger.error("Error while saving form", error);
				const errorMessage =
					// @ts-expect-error typed?
					(error.response && error.response.data) || error.message;
				if (/lid heeft al eerder/i.test(errorMessage)) {
					this.openModal({
						name: "proefsporter-bekend",
					});
				} else {
					this.popupStore.showError("Er ging iets mis bij het opslaan van het welkomstformulier");
				}
			} finally {
				this.overridenIsLoading = false;
			}
		},
		getMappedFormData(): IomodelsWelkomstFormulierExtern & Omit<IomodelsProefsporterLes, "lesId"> {
			return {
				email: this.formData.email,
				geslacht: this.formData.geslacht || "",
				geboorteDatum: this.formData.geboortedatum
					? getDateFromDatum(this.formData.geboortedatum).toISOString()
					: "",
				locatie: this.formData.locatie ?? this.currentGymSlug ?? DEFAULT_LOCATION,
				voornaam: this.formData.voornaam,
				achternaam: this.formData.achternaam,
				telefoonNummer: this.formData.telefoonNummer,
				mobielNummer: this.formData.telefoonMobiel,
				doel: this.formData.doel,
				frequentie: this.formData.frequentie,
				verwijzing: this.formData.verwijzing,
				interesses: this.formData.interests
					.filter((currentInterest) => currentInterest.value)
					.map((currentInterest) => currentInterest.key)
					.join(","),
			};
		},
	},
});
